import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/footer'
import Layout from '../components/layout'
import RecipePreview from '../components/recipe-preview'
import styles from './recipes.module.css'

class Recipes extends React.Component {
  render() {
    const recipes = get(this, 'props.data.allContentfulRecipe.edges')
    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title="Journey East" />
          <ul className="article-list" className={styles.recipeArea}>
            {recipes.map(({ node }) => (
              <RecipePreview key={node.slug} recipe={node} />
            ))}
          </ul>
        </div>
        <Footer />
      </Layout>
    )
  }
}

export default Recipes

export const pageQuery = graphql`
  query RecipesQuery {
    allContentfulRecipe(
      sort: { fields: [publishDate], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          title
          hanzi
          slug
          tagline {
            tagline
          }
          photo {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`
