import classnames from 'classnames'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styles from './recipe-preview.module.css'

export default ({ recipe, isBlueBackground = false, className }) => (
  <Link
    className={classnames(styles.preview, className)}
    to={`/recipe/${recipe.slug}`}
  >
    {recipe.photo && (
      <Img alt="" className={styles.photo} fluid={recipe.photo.fluid} />
    )}
    <h3
      className={classnames({
        [styles.noBorder]: isBlueBackground,
        [styles.withBorder]: !isBlueBackground,
      })}
    >
      <span className={styles.englishTitle}>
        {recipe.title}
        {recipe.hanzi && ` • `}
      </span>
      {recipe.hanzi && (
        <span style={{ whiteSpace: 'nowrap' }}>{recipe.hanzi}</span>
      )}
    </h3>
    {/**recipe.tags.map(tag => (
      <p className={styles.tag} key={tag}>
        {tag}
      </p>
    )) **/}
  </Link>
)
